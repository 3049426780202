'use strict';

import Flux                 from '../flux/Flux';
import {VXPay, VXPayAction} from './VXPay';
import Translations         from "./Translations";
import {exitFullScreen  }   from './Fullscreen';
import {getStaticAmount}    from './CommonUtils.js';

const TYPE_CHAT = 'chat';
const TYPE_SHOP = 'shop';

const SUCCESS_DIRECT  = 'direct';
const SUCCESS_PAYTOUR = 'paytour';

/**
 * @param type
 * @param price
 * @param needAvs
 * @param needVip
 * @param actorId
 * @param onSuccess
 * @param targetUrl
 * @param canVoucher
 */
function onEnoughMoney(type, price, needAvs, needVip, actorId, onSuccess, targetUrl, canVoucher) {
	// not logged in?
	if (!Flux.Guest.isLoggedIn() && (!Flux.Guest.isAnonymousVoicecall() || type !== TYPE_CHAT)) {
		switch (type) {
			case TYPE_SHOP:
				if (Flux.Guest.isAnonymousVoicecall()) {
					VXPay.openSignup({
						host:  actorId,
						texts: {
							BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
						},
					});
				} else {
					let action = null;
					if (typeof targetUrl !== 'undefined' && targetUrl) {
						action = new VXPayAction(Flux.Constants.ActionTypes.VXPay.REDIRECT_TO, {
							targetUrl: targetUrl,
						});
					}

					VXPay.openSignupOrLogin({}, action);
				}
				break;
			case TYPE_CHAT:
				VXPay.openSignupOrLogin(
					{
						host: actorId,
					},
					new VXPayAction(Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL, {
						targetId:             actorId,
						targetUrl:            targetUrl,
						storeAutoOpenSetting: true,
					})
				);
				break;
			default:
				if (Flux.Guest.isAnonymousVoicecall()) {
					VXPay.openSignup({
						host:  actorId,
						texts: {
							BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
						},
					});
				} else {
					VXPay.openSignupOrLogin({
						host: actorId,
					});
				}
				break;
		}

		return;
	}

	// block locked guests
	if (Flux.Guest.isLocked()) {
		return;
	}

	// check for VIP
	if (needVip) {
		VXPay.openAboPaytour();
		return;
	}

	// active voicecall is running
	if (type === TYPE_CHAT && Flux.Guest.isVoicecallActive()) {
		onSuccess();
	}
	// prepaid user with enough money
	else if (Flux.Guest.getBalance() >= price || canVoucher) {
		if (needAvs) {
			VXPay.openAVS();
			VXPay.onAVSSuccess(onSuccess);
		} else {
			onSuccess();
		}
	}
	// not enough money for action?
	else {
		exitFullScreen();
		if (needAvs) {
			VXPay.openPaytour();
			VXPay.onAVSSuccess(onSuccess);
		} else {
			if (Flux.Guest.canAutoRecharge()) {
				VXPay.openAutoRecharge(Flux.Guest.getAutoRechargeConfig(type, actorId));
			} else if (Flux.Guest.canOneClick()) {
				VXPay.openOneClick(Flux.Guest.getOneClickConfig(type, actorId));
			} else {
				VXPay.openPaytour();
			}
			VXPay.onMoneyChargeSuccess(onSuccess);
			// pass empty avs callback to avoid reloading the page while moneyChargeCallback is still running
			VXPay.onAVSSuccess(() => {});
		}
	}
}

// =============== public methods =====================

/**
 * @param actorId
 * @param price
 * @param onSuccess
 * @param targetUrl
 * @param voucherChatMinutes
 */
function onEnoughMoneyForChat(actorId, price, onSuccess, targetUrl, voucherChatMinutes = 0) {
	// if price somehow is not set use the predefined min balance
	if (typeof price === 'undefined' || price === null) {
        price = getStaticAmount('chatMinBalanceForChat');
    }

	onEnoughMoney(TYPE_CHAT, price, false, false, actorId, onSuccess, targetUrl, voucherChatMinutes > 0);
}

/**
 * @param actorId
 * @param price
 * @param needAvs
 * @param needVip
 * @param onSuccess
 * @param targetUrl
 * @param canVoucher
 */
function onEnoughMoneyForShop(actorId, price, needAvs, needVip, onSuccess, targetUrl, canVoucher) {
	onEnoughMoney(TYPE_SHOP, price, needAvs, needVip, actorId, onSuccess, targetUrl, canVoucher);
}

/**
 * @param accessSettings
 * @param {Function} onSuccess
 * @param {Function} [onAvsSuccess]
 * @param {Function} [onNeedBuying]
 * @param {Function} [onAboSuccess]
 */
function onContentAccess(accessSettings, onSuccess, onAvsSuccess, onNeedBuying, onAboSuccess) {
	onAvsSuccess = onAvsSuccess || onSuccess;
	onAboSuccess = onAboSuccess || onSuccess;

	if (accessSettings.needLogin && !Flux.Guest.isLoggedIn()) {
		if (typeof accessSettings.targetUrl !== 'undefined' && accessSettings.targetUrl) {
			VXPay.openSignupOrLogin(
				{},
				new VXPayAction(Flux.Constants.ActionTypes.VXPay.REDIRECT_TO, {
					targetUrl: accessSettings.targetUrl,
				})
			);
		} else {
			VXPay.openSignupOrLogin();
		}
	} else if (accessSettings.needAvs) {
		if (Flux.Guest.isPayingCustomer() || Flux.Guest.getBalance() > 0 || accessSettings.ignorePaymentFirst) {
			VXPay.openAVS();
		} else {
			VXPay.openPaytour();
		}
		VXPay.onAVSSuccess(() => {
			(typeof onSuccess === 'function') && onAvsSuccess(SUCCESS_PAYTOUR);
		});
	} else if (accessSettings.needVip) {
		VXPay.openAboPaytour();
		VXPay.onVIPAboSuccess(() => {
			(typeof onSuccess === 'function') && onAboSuccess(SUCCESS_PAYTOUR);
		});
	} else if (accessSettings.needPremium) {
		VXPay.openVXTVAbo();
	} else if (!accessSettings.needBuying) {
		(typeof onSuccess === 'function') && onSuccess(SUCCESS_DIRECT);
	} else if (accessSettings.needBuying) {
		(typeof onNeedBuying === 'function') && onNeedBuying();
	}
}

/**
 * @param onSuccess
 * @param actorId
 * @param useVXPayAction
 */
function onLivePreviewAccess(onSuccess, actorId, useVXPayAction) {
	if (!Flux.Guest.isLoggedIn()) {
		if (typeof actorId !== 'undefined' && actorId) {
			let callback;
			if (useVXPayAction) {
				callback = new VXPayAction(Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL, {
					targetId:             actorId,
					storeAutoOpenSetting: true,
				});
			} else {
				callback = () => {};
			}

			VXPay.openSignupOrLogin({host: actorId}, callback);
		} else {
			VXPay.openSignupOrLogin();
		}
	} else if (!Flux.Guest.isLocked() && typeof onSuccess === 'function') {
		onSuccess();
	}
}

/**
 * @param {Array} reasons
 * @return {{needVip: boolean, needLogin: boolean, needAvs: boolean, needBuying: boolean}}
 */
const settingsFromRestrictions = (reasons = []) => ({
	needLogin:  Array.isArray(reasons) && reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_LOGIN) !== -1,
	needAvs:    Array.isArray(reasons) && reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_AVS) !== -1,
	needVip:    Array.isArray(reasons) && reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_ABO) !== -1,
	needBuying: Array.isArray(reasons) && reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_PURCHASE) !== -1,
});

/**
 * @param {Array} reasons
 * @return {boolean}
 */
const needsAboAndHasAvs = (reasons = []) =>
	Flux.Guest.isLoggedIn()
	&& Array.isArray(reasons)
	&& reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_AVS) === -1
	&& reasons.indexOf(Flux.Constants.Guest.Restrictions.NEED_ABO) !== -1;

export default {
	onContentAccess,
	onEnoughMoneyForChat,
	onEnoughMoneyForShop,
	onLivePreviewAccess,
	settingsFromRestrictions,
	needsAboAndHasAvs,
	SUCCESS_DIRECT,
	SUCCESS_PAYTOUR,
};
